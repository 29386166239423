import styled from "styled-components"
import {dark} from "../../styles"

export const Divider = styled.div`
  width: 90%;
  height: 20px;
  background-color: #e6e8fa;
  grid-column: 1 / span 10;
  justify-self: start;
  border-radius: 0px 20px 20px 0px;
  box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 0.25),
    -5px -5px 9px 0 rgba(255, 255, 255, 1);
  @media (prefers-color-scheme: dark) {
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 1),
    -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
    background-color: ${dark};
  }
`
