import React from 'react'
import Image from '../components/image'
import Seo from '../components/seo'
import styled from 'styled-components'
import Loadable from 'react-loadable'
import IndexSection from '../components/indexSection'
import {Divider} from '../components/elements/divider/divider'
import Card from '../components/card'
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { ImgEdge, ImgHash} from "../helpers/interfaces"
import Splash from "../components/splash/splash"
import {isMobile} from 'react-device-detect'

type Props = {
  data: any
}

const Main = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 10%);
  width: 100vw;
  grid-row-gap: 100px;
  grid-template-rows: 30px 60vh 30px 50vh 30px 50vh 30px 50vh;
 
  /* Maximum aspect ratio */
  @media (max-aspect-ratio: 1/1) {
    grid-template-rows: 30px 30vh 30px 30vh 30px 30vh 30px 30vh;
  }
  @media (max-width: 1024px){
    grid-template-columns: repeat(10, 10%);
    @media (max-height: 769px) {
 
      grid-template-rows: 30px 60vh 30px 50vh 30px 50vh 30px 50vh;
    }
    grid-template-rows: 30px 50vh 30px 30vh 30px 30vh 30px 30vh;
  }
  @media (max-width: 769px){
    grid-template-columns: 100%;
    grid-template-rows: 30px auto 150vh 30px auto 150vh 30px auto 150vh 30px auto 150vh;
    @media (min-height: 500px) {
      grid-template-rows: 30px 70vh 70vh 30px 70vh 50vh 30px 70vh 50vh 30px 70vh 50vh;
    }
    grid-row-gap: 20px;
  }
  @media (max-width: 560px) {
    grid-template-rows: 30px auto 50vh 30px auto 50vh 30px auto 50vh 30px auto 50vh;
  }
  align-items: center;
  justify-items: center;
  margin-bottom: 10vh;
`
const LeftImageContainer = styled.div`
  width: 90%;
  justify-self: end;
  margin-bottom: 5%;
  grid-column: 1 / span 4;
  max-height: 100%;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 0.25),
    -5px -5px 9px 0 rgba(255, 255, 255, 1);
  @media (prefers-color-scheme: dark) {
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 1),
    -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
  }
  @media (max-width: 769px){
    grid-column: 1;
    justify-self: center;
    grid-row-start: ${props => props.row && props.row};
  }
`
const RightImageContainer = styled.div`
  width: 80%;
  max-height: 100%;
  overflow: hidden;
  grid-column: 6 / span 5;
  justify-self: center;
  border-radius: 20px;
  box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 0.25),
    -5px -5px 9px 0 rgba(255, 255, 255, 1);
  @media (prefers-color-scheme: dark) {
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 1),
    -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
  }
  @media (max-width: 769px){
    grid-column: 1;
    grid-row-start: ${props => props.row && props.row};
  }
`
const Divider1 = styled(Divider)`
  @media (max-width: 560px){
    grid-row-start: 1;
  }
`
const Divider2 = styled(Divider)`
  justify-self: end;
  border-radius: 20px 0px 0px 20px;
  @media (max-width: 560px){
    grid-row-start: 4;
  }
`
const Divider3 = styled(Divider)`
  @media (max-width: 560px){
    grid-row-start: 7;
  }
`
const Divider4 = styled(Divider)`
  justify-self: end;
  border-radius: 20px 0px 0px 20px;
  @media (max-width: 560px){
    grid-row-start: 10;
  }
`

const EditorContainer = styled.div`
  width: 90%;
  height: 100%;
  display: grid;
  justify-items: center;
  margin-left: 5%;
  box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 1);
  @media (prefers-color-scheme: dark) {
    box-shadow: 
    9px 9px 13px 0 rgba(0, 0, 0, 1),
    -5px -5px 9px 0 rgba(255, 255, 255, 0.2);
  }
  border-radius: 20px;
`

const LoadableEditor = Loadable({
  loader: () => import('../components/elements/editor'),
  loading() {
    return <div>Loading...</div>
  }
});

const IndexPage: React.FC<Props> = ({data}) => {
  const portfolioImgHash = data.portfolioImages.edges.reduce((map: ImgHash, obj: ImgEdge) => {
    map[obj.node.name] = obj.node.childImageSharp
    return map
  }, {})

  const newsImgHash = data.newsImages.edges.reduce((map: ImgHash, obj: ImgEdge) => {
    map[obj.node.name] = obj.node.childImageSharp
    return map
  }, {})
  return (
    <>
      <Seo title={"home"}/>
      { isMobile && <Splash title={data.site.siteMetadata.title} description={data.site.siteMetadata.description}/>}
      <Main>
        <Divider1 style={{background: "transparent", boxShadow: "none"}} />
        <IndexSection title={'Info'} col={"1 / span 5"} row={3} justify={'end'} height={'100%'} >
          <EditorContainer>
            <LoadableEditor name={"index info"} height="100%" width="100%"/>
          </EditorContainer>
        </IndexSection>
        <RightImageContainer row={2}>
          <Image name={'main'} shape={'main'}/>
        </RightImageContainer>
        <Divider2 />
        <LeftImageContainer row={5}>
          <Img fluid={newsImgHash[data.news.edges[0].node.frontmatter.imageName].fluid} style={{borderRadius: "20px"}}/>
        </LeftImageContainer>
        <IndexSection title={'News'} col={"5 / span 6"} justify={'center'} row={6} contentCols={data.news.edges.length}>
          {
            data.news.edges.map((edge: any, index: number) => {
              const { node: {
              frontmatter: {
                title,
                date,
                summary,
                path
              }
            } } = edge
              return <Link to={path} key={index} style={{textDecoration: "none", color: "black", width: "90%"}}><Card title={title} type={'post'} height={'70%'} footer={date}>{summary}</Card></Link>
            })}
        </IndexSection>
        <Divider3/>
        <IndexSection title={'Portfolio'} col={"1 / span 5"} justify={'end'} row={9} contentCols={data.portfolio.edges.length}>
          {
            data.portfolio.edges.map((edge: any, index: number) => {
              const {
              node: {
                project: {
                  title,
                  path,
                  img
                }
              }
            } = edge
              return <Link to={path} key={index} style={{textDecoration: "none", color: "black", width: "90%", height: "100%"}}><Card title={title} type={'img'} height={'70%'}><Img fluid={portfolioImgHash[img].fluid} style={{borderRadius: "20px"}}/></Card></Link>
            })}
        </IndexSection>
        <RightImageContainer row={8}>
          <Image name={'code'} shape={'square'}/>
        </RightImageContainer>
        <Divider4 height={"15px"} row={10}/>
        <LeftImageContainer row={11}>
          <Image name={'cats'} shape={'square'}/>
        </LeftImageContainer>
        <IndexSection title={'Blog'} col={"5 / span 6"} justify={'center'} row={12} contentCols={data.blog.edges.length}>
          {
            data.blog.edges.map((edge: any, index: number) => {const {
            node: {
              frontmatter: {
                title,
                date,
                summary,
                path
              }
            }
          } = edge
              return <Link to={path} key={index} style={{textDecoration: "none", color: "black", width: "90%"}}><Card title={title} type={'post'} height={'70%'} footer={date}>{summary}</Card></Link>
        })}
        </IndexSection>
      </Main>
    </>
  )
}

export const query = graphql`
    query Home {
        news: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: 3
            filter: {
                frontmatter: {type: {eq: "news"}}
            }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        type
                        title
                        summary
                        date
                        imageName
                    }
                }
            }
        }
        blog: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: 3
            filter: {
                frontmatter: {type: {eq: "blog"}}
            }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        type
                        title
                        summary
                        date
                    }
                }
            }
        }
        portfolio: allYaml(
            limit: 2
            sort: { order: ASC, fields: [project___order] }
        ) {
            edges {
                node {
                    project {
                        title
                        img
                        path
                    }
                }
            }
        }
        portfolioImages: allFile(
            filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "portfolioImages"}}
        ) {
            edges {
                node {
                    name
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        newsImages: allFile(
            filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "newsImages"}}
        ) {
            edges {
                node {
                    name
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title,
                description
            }
        }
    }
`

export default IndexPage
