import React from "react"
import styled from "styled-components"
import {Link} from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { handleLinkSelect, setPreviousLink } from "../state/actions/nav"
import {light, dark, highlight} from "./styles"

type Props = {
  height?: string | boolean,
  col?: String,
  title: String,
  justify?: String,
  row?: Number,
  contentCols?: Number | boolean
}

const IndexSectionContainer = styled.div`
 width: 100%;
 align-self: start;
 display: grid;
 grid-template-rows: 15% 80%;
 grid-template-columns: 5% 90% 5%;
  @media (max-width: 769px){
    grid-row-start: ${props => props.row && props.row};
  }
  @media (min-width: 769px) {
    justify-self: ${props => props.justify ? props.justify : "end"};
    grid-column: ${props => props.col && props.col};
  }
  height: 100%;
  min-height: 100%;
  max-height: 100%;
`

const IndexSectionTitle = styled.h1`
 color: ${highlight};
 : hover {
    color: ${dark};
 }
 @media(prefers-color-scheme: dark){
   : hover {
    color: ${light};
   }
 }
 height: 10%;
`
const IndexSectionContent = styled.div`
  grid-row-start: 2;
  grid-column-start: 2;
  display: ${props => props.contentCols && 'grid'};
  grid-template-columns: ${props => props.contentCols && props.contentCols > 1 ? `repeat(${props.contentCols}, ${100 / props.contentCols}%)` : `1, 100%`};
  grid-row-gap: 5%;
  justify-items: center;
  height: 100%;
  width: 100%;
`

const indexSection: React.FC<Props> = ({col, row, title, justify, height, children, contentCols}) => {
  const selectedLink = useSelector(state => state.nav.selectedLink)
  const dispatch = useDispatch()
  return <IndexSectionContainer id={title.toLowerCase()} col={col} row={row} justify={justify} height={height}>
    <Link
      to={"/" + title.toLowerCase()}
      style={{ textDecoration: "none", width: "auto", gridColumnStart: 2}} onClick={() => {
      dispatch(setPreviousLink(selectedLink))
      dispatch(handleLinkSelect(title.toLowerCase()))
    }}>
      <IndexSectionTitle>{title}</IndexSectionTitle>
    </Link>
    <IndexSectionContent contentCols={contentCols}>
      {children}
    </IndexSectionContent>
  </IndexSectionContainer>

}

export default indexSection
